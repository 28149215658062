import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/index.css';
import MasterPage from './MasterPage';



// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<MasterPage />, document.getElementById('root'));
// serviceWorker.unregister();
