/* eslint-disable import/prefer-default-export */
const CONSTANTS = {
  ACTION: {
    VIEW: 'view',
    EDIT: 'edit',
    DELETE: 'delete',
    ADD: 'add',
  },
  API: {
    LOGIN: '/callcenter-users-api/v1/users/login',
    GET_CALL_CENTER: '/callcenter-onboarding-api/v1/callcenter/findAll/dropdown',
    GET_CALL: '/callcenter-onboarding-api/v1/callcenter/findAll/asc/10/0',
    ADD_ROLE: '/callcenter-roles-api/roles/save',
    ADD_USER: '/callcenter-users-api/v1/users/save',
    CALL_CENTRE_POST: '/callcenter-onboarding-api/v1/callcenter/add',
    CALL_CENTRE_GET: '/callcenter-onboarding-api/v1/callcenter/findAll',
    GET_ALL_USER: '/callcenter-users-api/v1/users/findAll/users',
    GET_CALL_CENTER_BY_ROLE: '/callcenter-roles-api/roles/call-center',
    SAVE_USER: '/callcenter-users-api/v1/users/save',
    GET_ROLES: '/callcenter-roles-api/roles/fetch-all/fetchRoleByCallcenterName',
    DELETE_ROLES: '/callcenter-roles-api/roles/delete',
    GET_FIELD: '/callcenter-roles-api/roles/access/fetch-all',
    GET_ROLES_BY_ID: '/callcenter-roles-api/roles/call-center',
    GET_ALL_ROLE: '/callcenter-roles-api/roles/findAll',
    UPDATE_USER: '/callcenter-users-api/v1/users/update',
    DELETE_USER: '/callcenter-users-api/v1/users/delete',
    GET_USER_BY_ID: '/callcenter-users-api/v1/users/findById/Users',
    UPDATE_ROLE: '/callcenter-roles-api/roles/update',
    GET_CATEGORY_DD: '/callcenter-users-api/v1/users/categoryDropDown',
    UPLOAD_META_FILE: '/callcenter-records-api/v1/callcenter/meta-file/v2',
    UPLOAD_CALL_RECORD: '/callcenter-records-api/v1/callcenter/record-file',
    GET_META_FILE_DD: '/callcenter-records-api/v1/callcenter/metaFileDropDown',
    GET_RECORDS: '/callcenter-records-api/v1/reports/findAll/Users',
    GET_PHONECALL_RECORDS: '/callcenter-records-api/v1/download-call-records/findAll/Users',
    GET_BUSINESS_CATAGORY: 'callcenter-records-api/v1/callcenter/businessCategoryDropDown',
    DOWNLOAD_FILE: 'callcenter-records-api/v1/callcenter/download-file',
    GET_PREURL: 'callcenter-records-api/v1/callcenter/getPreSignedUrl',
    SAVE_DATA_DB: '/callcenter-records-api/v1/callcenter/saveDataIntoDb',
    DOWNLOAD_REPORT: '/callcenter-records-api/v1/reports/downloadReports',
    CHECK_STATUS: '/callcenter-records-api/v1/callcenter/checkStatusMetaFile',
    CHECK_STATUS_CALL: '/callcenter-records-api/v1/callcenter/checkStatus',
    CHECK_METAFILE_STATSU: '/callcenter-records-api/v1/callcenter/checkStatusMetaFile',
    LOGOUT: '/callcenter-users-api/v1/users/logout',
    CALL_RECORD_DETAILS: '/callcenter-records-api/v1/download-call-records/fetchstatusList',
    GET_CALL_RECORDS: '/callcenter-records-api/v1/callcenter/getCallRecords',
    GET_NEW_CALL_RECORD: '/callcenter-records-api/v1/callcenter/getAllRecords',
    GROUP_NAME_DROP_DOWN: '/callcenter-records-api/v1/callcenter/groupNameDropDown',
  },
  PATHS: {
    DEFAULT: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    USER: '/user',
    RESET: '/reset',
    ROLEMANAGEMENT: '/rolemanagement',
    METADATA_RECORD: '/metadata-record',
    CALL_RECORD: '/call-record',
    CALLCENTRE: '/callcentre',
    REPORTS: '/reports',
    DOWNLOAD_RECORD: '/download-record',
  },
  API_METHODS: {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
  },
  STATUS: {
    OK: 200,
    CREATED: 201,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    DATA_NOT_FOUND: 404,
    DUPLICATE_RECORD: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_APPLICABLE: 406,
    BAD_REQUEST: 400,
    UNPROCESSABLE_ENTITY: 422,
    UNSUPPORTED_MEDIA_TYPE: 415,
  },
  ENVIRONMENT_VAR: {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
  },
  KEY: 'HDFC Life',
  TOKEN: localStorage.getItem('X_ACCESS_TOKEN'),
};

export default CONSTANTS;

/* eslint-disable import/prefer-default-export */
// const CONSTANTS = {
//     ACTION: {
//         VIEW: 'view',
//         EDIT: 'edit',
//         DELETE: 'delete',
//         ADD: 'add'
//     },
//     API: {
//         LOGIN: 'callcenter-users-api/v1/users/login',
//         GET_CALL_CENTER: 'api/v1/callcenter/findAll/dropdown',
//         GET_CALL: '/api/v1/callcenter/findAll/asc/10/0',
//         ADD_ROLE: '/api/roles/save',
//         ADD_USER: '/callcenter-users-api/v1/users/save',
//         CALL_CENTRE_POST: '/api/v1/callcenter/add',
//         CALL_CENTRE_GET: '/api/v1/callcenter/findAll',
//         GET_ALL_USER: '/api/v2/users/findAll/Users',
//         GET_CALL_CENTER_BY_ROLE: '/api/roles/call-center',
//         SAVE_USER: '/apiv1/users/save',
//         GET_ROLES: 'api/v2/roles/fetch-all',
//         DELETE_ROLES: '/api/roles/delete',
//         GET_FIELD: '/api/roles/access/fetch-all',
//         GET_ROLES_BY_ID: '/api/roles/call-center',
//         GET_ALL_ROLE: '/api/roles/findAll',
//         UPDATE_USER: '/api/v1/users/update',
//         DELETE_USER: '/api/v1/users/delete',
//         GET_USER_BY_ID: '/api/v1/users/findById/Users',
//         UPDATE_ROLE: '/api/roles/update',
//         GET_CATEGORY_DD: '/api/v1/users/categoryDropDown',
//         UPLOAD_META_FILE: '/api/v1/callcenter/meta-file',
//         UPLOAD_CALL_RECORD: '/api/v1/callcenter/record-file',
//         GET_META_FILE_DD: '/api/v1/callcenter/metaFileDropDown',
//         GET_RECORDS: 'api/v1/reports/findAll/Users',
//         GET_PHONECALL_RECORDS:'api/v1/download-call-records/findAll/Users',
//         GET_BUSINESS_CATAGORY:'api/v2/callcenter/businessCategoryDropDown',
//         DOWNLOAD_FILE:'api/v2/callcenter/download-file',
//         GET_PREURL:'api/v1/callcenter/getPreSignedUrl',
//         SAVE_DATA_DB: 'api/v1/callcenter/saveDataIntoDb'
//     },
//     PATHS: {
//         DEFAULT: '/',
//         LOGIN: '/login',
//         SIGNUP: '/signup',
//         USER:'/user',
//         RESET: '/reset',
//         ROLEMANAGEMENT: '/rolemanagement',
//         METADATA_RECORD:'/metadata-record',
//         CALL_RECORD:'/call-record',
//         CALLCENTRE: '/callcentre',
//         REPORTS: '/reports',
//         DOWNLOAD_RECORD:'/download-record'
//     },
//     API_METHODS: {
//         GET: 'GET',
//         POST: 'POST',
//         DELETE: 'DELETE',
//         PUT: 'PUT'
//     },
//     STATUS: {
//         OK: 200,
//         CREATED: 201,
//         UNAUTHORIZED: 401,
//         FORBIDDEN: 403,
//         DATA_NOT_FOUND: 404,
//         DUPLICATE_RECORD: 409,
//         INTERNAL_SERVER_ERROR: 500,
//         NOT_APPLICABLE: 406,
//         BAD_REQUEST: 400,
//         UNPROCESSABLE_ENTITY: 422,
//         UNSUPPORTED_MEDIA_TYPE: 415,
//     },
//     ENVIRONMENT_VAR: {
//         LOCAL: 'local',
//         DEVELOPMENT: 'development',
//         STAGING: 'staging',
//         PRODUCTION: 'production',
//     }
// };

// export default CONSTANTS;
