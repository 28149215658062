import React from 'react';

export default function Error() {
    return (
        <>
          <div style={{ "margin-top": "5%", "margin-left": "1%" }}>
            <div className="headerline">
              <h1 style={{ color: "red" }}>Error 403 Forbidden</h1>
            </div>
          </div>
        </>
    );
}
